import React from "react";
import {TinyButton as ScrollUpButton} from "react-scroll-up-button";
export default class Index extends React.Component {
  render() {
    return (
      <div>
        <ScrollUpButton
          ContainerClassName="bg-transparent"
          EasingType="easeInOutQuad"
          ShowAtPosition={1500}
        />
      </div>
    );
  }
}
import React from "react";

import "./Destination.css";
import { Link } from "react-router-dom";
import { RedirectButton } from "../RedirectButton/RedirectButton";
import { TitleText } from "../TitleText/TitleText";
import { Col, Container } from "reactstrap";
// import { Row, Button } from "react-bootstrap";
import { index } from "../../variables/content";
import { GiBearFace, GiCampingTent } from "react-icons/gi";
import { FaBinoculars, FaPrayingHands } from "react-icons/fa";
import { BiHotel } from "react-icons/bi";
import { RiRestaurant2Line } from "react-icons/ri";

window.$tmp = {};
window.$cat = "all";
class Destinations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Cards: [],
      isEmpty: true,
      isLoading: true,
    };
  }

  async componentDidMount() {
    let Cards = index.home.topAttraction.categories;
    if (Cards === null) {
      this.setState({ isEmpty: true, isLoading: false });
    } else if (Cards.length > 0) {
      this.setState({ Cards: Cards, isEmpty: false, isLoading: false });
    }
  }

  render() {
    let categoryList = function (category) {
      if (category === "Adventure")
        return <GiCampingTent className="cat-icon" />;
      else if (category === "Wildlife")
        return <GiBearFace className="cat-icon" />;
      else if (category === "Sightseeing")
        return <FaBinoculars className="cat-icon" />;
      else if (category === "Divine")
        return <FaPrayingHands className="cat-icon" />;
      else if (category === "Hotel") return <BiHotel className="cat-icon" />;
      else if (category === "Food")
        return <RiRestaurant2Line className="cat-icon" />;
    };
    let cardItems = this.state.Cards.map((card) => {
      return (
        <Col
          className="attr-col"
          md={card.width}
          xs="6"
          onClick={() => (window.$cat = card.category)}
        >
          <li>
            <Link to="/explore">
              <img top className="attr-img" src={card.image} alt={card.head} />
              <div className="attr-content">
                <div class="attr-title">
                  <div>{categoryList(card.category)}</div>
                  {card.head}
                  <br />
                  <br />
                </div>
              </div>
            </Link>
          </li>
        </Col>
      );
    });
    return (
      <div>
        <div className="tourism-banner-container red-bg justify-content-center">
          <div classname="col-xs-12 col-sm-10 col-md-8">
            <TitleText text={index.home.topAttraction.head} color="#4a6d39" />
            <p class="text-black">{index.home.topAttraction.description}</p>
          </div>
        </div>
        <div>
          <Container fluid className="attr-container">
            {cardItems}
          </Container>
        </div>

        <div className="margin-button">
          <a href="./explore">
            <RedirectButton buttonColor="#4A6D39" children="View All" />
          </a>
        </div>
      </div>
    );
  }
}

export default Destinations;

import React from "react";
import "./Hero.css";

const Hero = (props) => {
  return (
    <div>
      <header>
        <div className="overlay"></div>
        <img
          className="overlay"
          src={window.outerWidth > 1000 ? props.img : props.pimg}
          alt={props.alt}
        />

        <div className="container  h-100">
          <div className="d-flex h-100 text-center text-white align-items-center">
            <h1 className="text-weight-bold header-text">{props.alt}</h1>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Hero;

import React, {useState} from "react";
import "./Registration.css";
import { Link } from "react-router-dom";
import { Button, Col, Row, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { index } from "../../variables/content";

export const Registration = ({ color }) => {

const [modal, setModal] = useState(false);
const toggle = () => setModal(!modal);

  return (
    <div style={{ backgroundColor: color }} className="px-5 py-4">
      <h2 className="text-center mb-5 head">{index.registration.head}</h2>
      <Row>
        <Col md="9">
          <p className="description">{index.registration.description}</p>
        </Col>
        <Col className="volunteer_button" md="3">
          <Button className="join-btn"  onClick={toggle}>
            {index.registration.joinButton.text}
          </Button>
          <Link to={index.registration.conButton.link}>
            <Button
              className="contact-btn"
              style={{ backgroundColor: color}}
            >
              {index.registration.conButton.text}
            </Button>
          </Link>
        </Col>
      </Row>
      
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Coming soon!</ModalHeader>
        <ModalBody>
          We're coming with some updates soon. Stay tuned!
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
  
    </div>
  );
};

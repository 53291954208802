import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import WeatherWidget from "../../shared-components/WeatherWidget/WeatherWidget";
// import { Navbar, Nav, Button } from 'react-bootstrap'

import "./navigation.css";
import logo from "../../Images-Videos-GIF/logo.png";
import { index } from "../../variables/content";

const Navigation = (isCoffee) => {
  const location = useLocation();
  const [navbar, setnavbar] = useState(false);

  const changeNavbarBackground = () => {
    // console.log(window.scrollY);
    if (window.scrollY > 400) {
      setnavbar(true);
    } else {
      setnavbar(false);
    }
  };

  window.addEventListener("scroll", changeNavbarBackground);

  let navListHead = index.header.navigation.map((nav) => {
    return (
      <Nav.Link
        href={nav.link}
        className={
          location.pathname === nav.link ? "active-link" : "inactive-link"
        }
      >
        {nav.head}
      </Nav.Link>
    );
  });
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      bg={`${location.pathname === "/" ? "trans" : "dark"} ${
        navbar ? "navbar-active" : ""
      }`}
      className={location.pathname === "/coffee" ? "coffee-back" : ""}
      variant="dark"
      fixed="top"
    >
      <Navbar.Brand href="./">
        <img
          alt=""
          src={logo}
          width="70"
          height="70"
          className="d-inline-block align-top"
          style={{ padding: 5, marginLeft: 25 }}
        />{" "}
        {/* Chikkamagaluru */}
      </Navbar.Brand>

      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">{navListHead}</Nav>

        <WeatherWidget />
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Navigation;

import React from "react";
import "./TopBlogs.css";
import { Link } from "react-router-dom";
import { Container, Row } from "react-bootstrap";
import { RedirectButton } from "../RedirectButton/RedirectButton";
import { TitleText } from "../TitleText/TitleText";
window.$tmp = {};
class TopBlogs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Cards: [],
      isEmpty: true,
      isLoading: true,
    };
  }

  async componentDidMount() {
    let Cards = [];
    let incomingData = [];
    let stopper = 5;
    await fetch(
      "https://chikkamagaluru-1fa59-default-rtdb.firebaseio.com/blog.json"
    )
      .then((res) => res.json())
      .then((data) => (incomingData = data));
    for (var key in incomingData) {
      if (stopper < 1) break;
      stopper--;
      Cards.push(incomingData[key]);
    }
    if (Cards === null) {
      this.setState({ isEmpty: true, isLoading: false });
    } else if (Cards.length > 0) {
      this.setState({ Cards: Cards, isEmpty: false, isLoading: false });
    }
  }

  render() {
    let cardItems = this.state.Cards.slice(0, 4).map((card) => {
      return (
        <div
          class="gallery-container w-2 h-2"
          onClick={() => {
            window.$tmp = card;
            localStorage.setItem("tmpBlogUser", JSON.stringify(card));
          }}
        >
          <Link to={`/blog-detail/${card.index}`}>
            <div class="gallery-item">
              <div class="image">
                <img src={card.tile_image} alt={card.title} />
              </div>
              <div class="text1">{card.title.slice(0, 26) + "..."}</div>
              <div class="text2">
                {card.body.slice(0, 100) + "..."}
                <button className="blog-btn">Read More</button>
              </div>
            </div>
          </Link>
        </div>
      );
    });

    let lastItems = this.state.Cards.slice(4, 5).map((card) => {
      return (
        <div
          class="gallery-container w-4 h-2"
          onClick={() => {
            window.$tmp = card;
            localStorage.setItem("tmpBlogUser", JSON.stringify(card));
          }}
        >
          <Link to={`/blog-detail/${card.index}`}>
            <div class="gallery-item">
              <div class="image">
                <img src={card.tile_image} alt={card.title} />
              </div>
              <div class="text1">{card.title}</div>
              <div class="text2">
                {card.description.slice(0, 100) + "..."}
                <button className="blog-btn">Read More</button>
              </div>
            </div>
          </Link>
        </div>
      );
    });

    return (
      <Container fluid className="main">
        <Container className="text-center">
          <TitleText text="Plan Your Visit" color="#fff" />
          <h2 className="blog-heading-section">
            Top Travel Destination Blogs - We Can't Wait to Have you Back
          </h2>
        </Container>

        <div className="container1">
          {cardItems}
          {lastItems}
        </div>

        <Row className="mt-5">
          <a href="./blog" className="mx-auto">
            <RedirectButton buttonColor="#689D4F" children="More" />
          </a>
        </Row>
      </Container>
    );
  }
}

export default TopBlogs;

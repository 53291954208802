import React from "react";
import "./cardsPage.css";
import Fuse from "fuse.js";
import ReactLoading from "react-loading";
import { TitleText } from "../../shared-components/TitleText/TitleText";
import Footer from "../../shared-components/Footer/Footer";
// import { BsPen } from "react-icons/bs";
import { Link } from "react-router-dom";
import { AiOutlineSearch, AiOutlineClose } from "react-icons/ai";

import {
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";

window.$tmp = {};
let Cards = [];
class CardsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Cards: [],
      tmp: [],
      isEmpty: true,
      isLoading: true,
      isSearched: false,
      searchPattern: "",
      Pages: [],
      Authors: [],
      activePage: 1,
    };
  }

  async componentDidMount() {
    Cards = [];
    let Pages = [];
    let Authors = [];
    await fetch(
      "https://chikkamagaluru-1fa59-default-rtdb.firebaseio.com/blog.json"
    )
      .then((res) => res.json())

      .then((data) => {
        for (let eachData in data) {
          Cards.push(data[eachData]);
          Authors.push(data[eachData].author);
        }
      });
    if (Cards === null) {
      this.setState({ isEmpty: true, isLoading: false });
    } else if (Cards.length > 0) {
      for (var num = 1; num <= Math.floor(Cards.length / 9) + 1; num++) {
        Pages.push(num);
      }
      this.setState({
        Cards: Cards.slice(
          (this.state.activePage - 1) * 8,
          this.state.activePage * 8
        ),
        tmp: Cards,
        isEmpty: false,
        isLoading: false,
        searchPattern: "",
        isSearched: false,
        Pages: Pages,
        Authors: [...new Set(Authors)],
      });
    }
    window.scrollTo(0, 0);
  }
  changePage(page) {
    this.setState({
      Cards: this.state.tmp.slice((page - 1) * 8, page * 8),
      activePage: page,
    });
    window.scrollTo(0, 0);
  }
  searchThis(pattern, fields) {
    if (!pattern) {
      this.setState({
        Cards: Cards.slice(
          (this.state.activePage - 1) * 8,
          this.state.activePage * 8
        ),
        isSearched: false,
      });
      return;
    }

    const fuse = new Fuse(Cards, {
      keys: fields,
    });
    const result = fuse.search(pattern);
    const matches = [];
    if (!result.length) {
      this.setState({ Cards: [] });
    } else {
      result.forEach(({ item }) => {
        matches.push(item);
      });
      this.setState({ Cards: matches, isEmpty: false, isSearched: true });
    }
  }

  render() {
    let cardItems = this.state.Cards.map((card) => {
      let tagItems = card.tags.split(" ").map((tag) => {
        return (
          <span
            onClick={() => {
              this.searchThis(tag, ["tags"]);
            }}
            className="px-2 py-1 font-weight-bold mr-2 tags"
          >
            {tag}
          </span>
        );
      });
      return (
        <Col className="mb-4 padding-xs-none" md="3" xs="12">
          <div className="post-module hover">
            <Link to={`/blog-detail/${card.index}`}>
              <div className="thumbnail">
                <img className="image-main" src={card.tile_image} alt="" />
              </div>
            </Link>
            <div className="post-content">
              <Link to={`/blog-detail/${card.index}`}>
                <h1 className="title">{card.title}</h1>
                <p className="description">
                  {card.description.slice(0, 100) + "..."}
                </p>
                <div className="post-meta"></div>
              </Link>
              <div className="tags-container w-100">{tagItems}</div>
            </div>
          </div>
        </Col>
      );
    });
    let pageItems = this.state.Pages.map((num) => {
      return (
        <PaginationItem>
          <PaginationLink onClick={() => this.changePage(num)}>
            {num}
          </PaginationLink>
        </PaginationItem>
      );
    });
    return (
      <>
        <div className="CardsPage top-margin">
          <div className="mx-4">
            <Row>
              <Col md="8">
                <div className="blogTitle p-3">
                  <TitleText text="Blogs" color="#4a6d39" />
                </div>
              </Col>
              <Col md="4">
                <div className="Search p-3">
                  <input
                    className="SearchInput"
                    type="text"
                    placeholder="Search"
                    value={this.state.searchPattern}
                    onChange={(e) =>
                      this.setState({ searchPattern: e.target.value })
                    }
                  />

                  <span
                    className="SearchClear"
                    onClick={() => {
                      this.setState({ searchPattern: "", isSearched: false });
                      this.searchThis("", ["title", "author", "tags"]);
                    }}
                  >
                    <AiOutlineClose
                      className={
                        this.state.searchPattern.trim().length === 0
                          ? "d-none"
                          : "d-auto"
                      }
                    />
                  </span>
                  <span
                    className="SearchSpan"
                    onClick={() => {
                      this.searchThis(this.state.searchPattern, [
                        "title",
                        "author",
                        "tags",
                      ]);
                    }}
                  >
                    <AiOutlineSearch />
                  </span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="12" className="p-0">
                <div className="mx-4 my-4">
                  <div className={this.state.isLoading ? "d-auto" : "d-none"}>
                    <ReactLoading
                      className="mx-auto mt-5"
                      type="spin"
                      color="#4A6D39"
                      height={100}
                      width={50}
                    />
                  </div>
                  <Row>{cardItems}</Row>
                  <h2
                    className={
                      this.state.Cards.length > 0 || this.state.isLoading
                        ? "d-none"
                        : "d-auto"
                    }
                  >
                    No results found
                  </h2>
                </div>
              </Col>
            </Row>

            <Row
              className={this.state.isSearched ? "d-none mb-5" : "d-auto mb-5"}
            >
              <Pagination
                className="mx-auto"
                aria-label="Page navigation example"
              >
                <PaginationItem>
                  <PaginationLink
                    previous
                    disabled={this.state.activePage === 1}
                    onClick={() => this.changePage(this.state.activePage - 1)}
                  />
                </PaginationItem>
                {pageItems}
                <PaginationItem>
                  <PaginationLink
                    next
                    disabled={
                      this.state.activePage ===
                      this.state.Pages[this.state.Pages.length - 1]
                    }
                    onClick={() => this.changePage(this.state.activePage + 1)}
                  />
                </PaginationItem>
              </Pagination>
            </Row>
          </div>
        </div>

        <Footer colorMode="green" />
      </>
    );
  }
}

export default CardsPage;

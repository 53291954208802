import * as firebase from "firebase";
var config = {
  apiKey: "AIzaSyD7XUotFSwf1GElp8_OHhdV0AKL9m3bV9E",
  authDomain: "chikkamagaluru-1fa59.firebaseapp.com",
  databaseURL: "https://chikkamagaluru-1fa59-default-rtdb.firebaseio.com",
  projectId: "chikkamagaluru-1fa59",
  storageBucket: "chikkamagaluru-1fa59.appspot.com",
  messagingSenderId: "645865443029",
  appId: "1:645865443029:web:a9d3c4656bd168ee875c5c",
};
// Initialize Firebase
firebase.initializeApp(config);

export default firebase;

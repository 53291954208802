import React from "react";
import "./Home.css";

// Components
import Hero from "../../shared-components/Hero Section - Video/Hero";
import Destination from "../../shared-components/Top Destination/Destination";
import TopBlogs from "../../shared-components/Top Blogs/TopBlogs";
import Footer from "../../shared-components/Footer/Footer";
import { Container, Row, Col } from "react-bootstrap";
import { RedirectButton } from "../../shared-components/RedirectButton/RedirectButton";
import { Registration } from "../../shared-components/Registration/Registration";
import ScrollUp from "../../shared-components/Bottom to Top/ScrollUp";

// Resources (Images-GIFS-Videos)
import coffee from "../../Images-Videos-GIF/cup-of-coffee.png";
import logo from "../../Images-Videos-GIF/1.png";
import { index } from "../../variables/content";

function Home() {
  let pItems = index.home.coffee.description.map((text) => {
    return <p>{text}</p>;
  });
  return (
    <>
      <div className="home overflow-hidden">
        <Hero />
        <Destination />
        <br />
        <TopBlogs />

        <Container fluid className="about_description_container">
          <Row className="row align-items-center">
            <Col className="chikkamagaluru-logo" xl={3} lg={3} md={4}>
              <img
                height="260px"
                className="chikka-logo"
                src={logo}
                alt="logo"
              ></img>
            </Col>

            <Col xl={9} lg={9} md={8}>
              <h1 className="font-weight-light more-top-bottom-margin">
                {index.home.about.head}
              </h1>
              <p className="about_description">
                {index.home.about.description}
              </p>
            </Col>
          </Row>
        </Container>

        <Container fluid className="about-coffee-container">
          <Row className="row align-items-center">
            <Col xl={8} lg={8} md={7}>
              <h1 className="font-weight-light margin-below">
                {index.home.coffee.head}
              </h1>
              <p className="about_description">{pItems}</p>

              <Row className="coffee-btne" fluid>
                <a href="./coffee">
                  <RedirectButton
                    buttonColor={index.home.coffee.button.color}
                    children={index.home.coffee.button.text}
                  />
                </a>
              </Row>
            </Col>

            <Col
              xl={4}
              lg={4}
              md={5}
              className="video-container align-self-center"
            >
              <img
                className="transparent-img"
                src={coffee}
                height="350"
                alt="coffee-mug"
              />
            </Col>
          </Row>

          {/* <Row>
            <Col md="2">
              <h3 className="text-left mt-5">
                {" "}
                <strong> &nbsp;&nbsp; SERVED AT</strong>
              </h3>
            </Col>
            <Col md="10">
              <img src={servedat} alt="coffee"></img>
            </Col>
          </Row> */}
        </Container>
      </div>

      <Registration color={index.home.registration.color} />
      <ScrollUp />
      <Footer colorMode={index.home.footer.color} />
    </>
  );
}

export default Home;

import React from "react";
import { Container } from "react-bootstrap";
// import bus from "../../Images-Videos-GIF/bus.gif";
// import plane from "../../Images-Videos-GIF/plane.gif";
// import train from "../../Images-Videos-GIF/train.gif";
import Footer from "../../shared-components/Footer/Footer";
import chikkamagaluru from "../../Images-Videos-GIF/Artboard.png";
import { index } from "../../variables/content";
import { Registration } from "../../shared-components/Registration/Registration";
import Insta from "../../shared-components/Instagram/Insta";
import Faq from "../../shared-components/FAQ/Faq";

import "./about.css";

const About = () => {
  let pItems = index.about.description.map((text) => {
    return <p className="about-description">{text}</p>;
  });
  return (
    <>
      <div>
        <div className="mt-5 extraa-travel" sm={true}></div>
        <img src={chikkamagaluru} fluid className="about-banner" alt="" />
        <Container fluid>
          <div className="mb-5 about-section">{pItems}</div>
        </Container>
      </div>
      <Registration color={index.about.registration.color} />
      <div className="registration-container">
        <Faq />
      </div>
      <Container fluid>
        <Insta />
      </Container>
      <Footer colorMode={index.about.footer.color} />
    </>
  );
};

export default About;

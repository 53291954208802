import React, { useState } from "react";
import "./contact.css";
import firebase from "../../variables/config";
import { Modal, Row, Col } from "react-bootstrap";
import Footer from "../../shared-components/Footer/Footer";
import { v1 as uuidv1 } from "uuid";
import { AiFillCloseCircle } from "react-icons/ai";

// Assests
import concover from "../../Images-Videos-GIF/2.jpg";

function Contact() {
  window.scrollTo(0, 0);
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [notification, setNotification] = useState("");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  async function addMessage(e) {
    if (
      name.trim().length === 0 ||
      subject.trim().length === 0 ||
      message.trim().length === 0 ||
      email.trim().length === 0
    ) {
      setNotification("Fields cannot be empty");
      setShow(true);
    } else {
      var uniqueId = uuidv1();
      var datentime = String(new Date());
      var MessageRef = firebase
        .database()
        .ref()
        .child("message/" + uniqueId + datentime);
      MessageRef.set(
        {
          name: name,
          subject: subject,
          message: message,
          index: uniqueId + datentime,
          datentime: datentime,
          email: email,
        },
        (error) => {
          if (error) {
            setNotification("Failed");
            setShow(true);
          } else {
            setNotification("Submitted");
            setShow(true);
          }
        }
      );
      setName("");
      setSubject("");
      setMessage("");
      setEmail("");
    }
  }
  return (
    <>
      <div className="top-margin">
        <Row className="w-100 h-8">
          <Col md="6" className="p-0 d-none contact-max-height d-sm-block">
            <img
              className="float-left img-left h-80"
              src={concover}
              alt="contact"
            />
          </Col>
          <Col className="contact-form contact-max-height" md="6">
            <div className="m-5 ">
              <h1 className="w-100 con-head text-center text-md-left mb-5">
                Contact
              </h1>
              <form>
                <Row>
                  <input
                    className="con-input"
                    placeholder="Name"
                    required=""
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    type="text"
                  />
                </Row>
                <Row>
                  <input
                    className="con-input"
                    name="Subject"
                    placeholder="Subject"
                    value={subject}
                    type="text"
                    onChange={(e) => {
                      setSubject(e.target.value);
                    }}
                  />
                </Row>
                <Row>
                  <input
                    className="con-input"
                    placeholder="Email"
                    required=""
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    type="text"
                  />
                </Row>
                <Row>
                  <textarea
                    className="con-input con-textarea"
                    name="Message"
                    placeholder="Write your message"
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                  />
                </Row>
                <Row>
                  <button
                    className="con-btn thar-two"
                    type="button"
                    onClick={() => {
                      addMessage();
                    }}
                  >
                    SEND
                  </button>
                </Row>
              </form>
            </div>
          </Col>
        </Row>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header className="modal-contact">
            <Modal.Title className="modal-contact-text">
              {notification}
            </Modal.Title>
            <AiFillCloseCircle
              className="close-btn-con"
              onClick={handleClose}
            />
          </Modal.Header>
        </Modal>
      </div>
      <Footer colorMode="green" />
    </>
  );
}

export default Contact;

let index = {
  home: {
    topAttraction: {
      head: "Top Attraction",
      description:
        "From outdoor adventures and amazing arts scenes to engaging events and family fun, chikkamagaluru has so many unique destinations to explore. Here’s a look at some of our most popular and recommended areas of chikkamagaluru. Find It Here.  ",
      categories: [
        {
          head: "Adventures",
          category: "Adventure",
          width: "6",
          image:
            "https://firebasestorage.googleapis.com/v0/b/chikkamagaluru-1fa59.appspot.com/o/158608330_167391725198433_6824209659785155408_n.jpg1618212216657?alt=media&token=f9e4c4ce-cfbf-4529-81e0-01a305710a5a",
        },
        {
          head: "Wildlife",
          category: "Wildlife",
          width: "3",
          image:
            "https://firebasestorage.googleapis.com/v0/b/chikkamagaluru-1fa59.appspot.com/o/169271685_895128204396684_6813620803273726584_n.jpg1618213753556?alt=media&token=6577a14e-57cf-45c0-9b7b-e58d8c671f1f",
        },
        {
          head: "Sightseeing",
          category: "Sightseeing",
          width: "3",
          image:
            "https://firebasestorage.googleapis.com/v0/b/chikkamagaluru-1fa59.appspot.com/o/120123150_2725651101019825_4376236439924972889_n.jpg1618212473424?alt=media&token=02878c08-f2ad-469b-a4cc-dd1fe5b1eeb9",
        },
        {
          head: "Divine Destinations",
          category: "Divine",
          width: "3",
          image:
            "https://firebasestorage.googleapis.com/v0/b/chikkamagaluru-1fa59.appspot.com/o/WhatsApp%20Image%202021-03-20%20at%2021.23.03.jpeg1616255693335?alt=media&token=8535a926-4486-4778-a399-62a705c91813",
        },
        {
          head: "Hotels and stays",
          category: "Hotel",
          width: "3",
          image:
            "https://scontent-otp1-1.cdninstagram.com/v/t51.2885-15/e35/169061961_754616395172679_6786714138541235958_n.jpg?tp=1&_nc_ht=scontent-otp1-1.cdninstagram.com&_nc_cat=103&_nc_ohc=RUQRcMSSVp4AX8Dgc5L&edm=APU89FAAAAAA&ccb=7-4&oh=f67c7d5898d13cd222af534f84f12bf5&oe=609DB1E0&_nc_sid=86f79a",
        },
        {
          head: "Food",
          category: "Food",
          width: "6",
          image:
            "https://1.bp.blogspot.com/-R8lQfqkcR7w/WWn6AzKh2gI/AAAAAAAAaKM/PF-XNql-uewdLrxOlnjP1n4tBXQqIu2mQCLcBGAs/s1600/7CT_7646.jpg",
        },
      ],
    },
    about: {
      head: "About Chikkamagaluru",
      description:
        "Chikmagalur or Chikkamagaluru, is a district in the state of Karnataka. Coffee was first cultivated in India in Chikmagalur.The mountains in Chikmagalur which are a part of the Western Ghats are the source of rivers like Tunga and Bhadra. Mullayanagiri, which is the highest peak in Karnataka is located in the district. It is also a tourist's paradise containing hill stations like Kemmanagundi and Kudremukh and waterfalls like Manikyadhara, Hebbe, Kallathigiri",
    },
    coffee: {
      head: "You already love our coffee!",
      description: [
        "Located at the foothills of the Mullayanagiri range, and just south of Baba Budangiris, Chikmagalur is famous for its forests, mountains wildlife and in particular peacocks. Widely known as the “coffee land” of Karnataka, it is one of the largest producers of coffee in India.",
        "Over the course of 20+ years, our business has grown and evolved as has our love for coffee. With an acumen for delivering consistent coffee, we have expanded our horizons to deliver our speciality blends straight to your doorstep. We've introduced premium Automatic Coffee Machines for homes and offices from Jura & Gaggia. Our pursuit for the one true cup drives us to achieve the highest level of quality, consistent flavour and all-around memorable cup of coffee. You've experienced it, now it's your turn to make it at home.",
      ],
      button: {
        text: "Know more",
        link: "",
        color: "#906219",
      },
    },
    registration: {
      color: "white",
    },
    footer: {
      color: "green",
    },
  },
  registration: {
    head: "Volunteer Registration",
    description:
      "If you are a resident of Chikmaguluru, willing to help the tourists, content writers, bloggers, and photographers visiting your own town, you can register yourself as a Tourist Volunteer. Thereby, if any traveler faces a need for a volunteer, you will be contacted. Join us, to help your town grow, with more tourism.",
    joinButton: {
      text: "Join Us",
      link: "#",
    },
    conButton: {
      text: "Contact Us",
      link: "/contact",
    },
  },
  about: {
    head: "",
    description: [
      "“Chikkamagaluru.org” is a private visitor center whose mission is to drive more attention to the Chikkamagaluru district and make it easy for travelers to have memorable leisure travel experiences. We love to spend all day helping more people discover the real Chikkamagaluru, and figure out what to see, do and eat.",
      "We are a community-driven platform, we work with the local businesses, government officials, and students to manage the traveler support system. we use cartography, social media commercials, print ads, outdoor advertising, and many more to promote.",
      "Chikkamagaluru is more than a leisure travel destination, it is the coffee capital of India, we have a huge agricultural industry that exports throughout the world, marketing around the district of Chikkamagaluru will increase the brand value which results in economic growth.",
    ],
    registration: {
      color: "white",
    },
    footer: {
      color: "green",
    },
  },
  header: {
    navigation: [
      { head: "Home", link: "/" },
      { head: "About", link: "/about" },
      { head: "Explore", link: "/explore" },
      { head: "Blogs", link: "/blog" },
      { head: "Contact Us", link: "/contact" },
    ],
  },
  footer: {
    navigationLeft: [
      {
        head: "Top Attractions",
        link: "/explore",
        items: ["Blogs", "Photos"],
      },
      { head: "Blogs", link: "/blog", items: ["Blogs", "Photos"] },
    ],
    navigationRight: [
      { head: "Coffee", link: "/coffee", items: ["Blogs", "Photos"] },
      { head: "Contact", link: "/contact", items: ["Blogs", "Photos"] },
    ],
    description:
      "Located at the foothills of the Mullayanagiri range, and just south of Baba Budangiris, Chikmagalur is famous for its forests, mountains wildlife and in particular peacocks. Widely known as the “coffee land” of Karnataka, it is one of the largest producers of coffee in India.",
    "social-links": {
      facebook: "",
      instagram: "",
    },
  },
};

module.exports = {
  index,
};

import { useEffect, useState } from "react";
import { Row, Col, Modal, Carousel } from "react-bootstrap";
import { AiOutlineArrowRight, AiFillCloseCircle } from "react-icons/ai";
import Markdown from "markdown-to-jsx";
import "./map.css";

export default function ItemList({ uId, data }) {
  const [show, setShow] = useState(false);
  const [modaldata, setModaldata] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (uId) {
      const element = window.document.getElementById("uId" + uId);
      element.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  }, [uId]);

  function showmodel(modeldata) {
    console.log({ modeldata });
    setModaldata(modeldata);
    handleShow();
  }

  return (
    <div className="sidebar">
      <Modal show={show} onHide={handleClose} animation={true}>
        <Modal.Body className="p-0">
          <AiFillCloseCircle className="close-btn" onClick={handleClose} />
          <Carousel>
            <Carousel.Item>
              <img
                className="d-block w-100 rounded-top"
                src={modaldata.image}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100 rounded-top"
                src={modaldata.tile_image}
                alt="Second slide"
              />
            </Carousel.Item>
          </Carousel>
          <h3 className="dest-title">{modaldata.destname}</h3>
          <div className="m-4">
            <Markdown>{modaldata.body}</Markdown>
          </div>
        </Modal.Body>
      </Modal>

      <Row className="w-100 mx-0">
        {data.map((card, index) => (
          <>
            <Col md="4" className="px-2" id={"uId" + index}>
              <div
                className="news-card mx-auto my-2"
                onClick={() => showmodel(card)}
              >
                <img
                  src={card.tile_image}
                  alt=""
                  className="news-card__image"
                />
                <div className="news-card__text-wrapper">
                  <h2 className="news-card__title">{card.destname}</h2>
                  <div className="news-card__details-wrapper">
                    <p className="news-card__excerpt">
                      {card.body.slice(0, 100) + "..."}
                    </p>
                    <p
                      onClick={() => showmodel(card)}
                      className="news-card__read-more"
                    >
                      Read more <AiOutlineArrowRight />
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </>
        ))}
      </Row>
    </div>
  );
}

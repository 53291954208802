import React from "react";
import "./Coffee.css";
import Footer from "../Footer/Footer";
import { GiCoffeeCup } from "react-icons/gi";

import lightroast from "./lightroast.jpg";
import mediumroast from "./mediumroast.jpg";
import mediumdarkroast from "./mediumdarkroast.jpg";
import darkroast from "./darkroast.jpg";
import coffeecup from "./coffeecup.png";

//New
import coffeeimages from "./coffee22.png";

const Coffee = () => {
  return (
    <>
      <div className=" coffee-top-margin coffee_banner ">
      </div>

      <div>
        <div className="coffeeheader_container ">
          <div className="coffee_heading_text_container  coffee_heading_item">
            <h1 className="coffeeheading">CHIKKAMAGLURU</h1>
            <h2 className="coffeesubheading">THE LAND OF COFFEE</h2>
            <button className="cof-btn">
              <h3 className="btn-text">Get Started</h3>
            </button>
          </div>

          <div coffee_heading_item>
            <img className="coffeeimage" src={coffeeimages} alt="coffee"></img>
          </div>
        </div>
      </div>
      <div className="coffee_info_container">
        <div className="coffee_info_sub_container">
          <h1 className="coffee_info">4</h1>
          <h2 className="coffee_info">Degrees</h2>
        </div>
        <div className="coffee_info_sub_container">
          <h1 className="coffee_info">5</h1>
          <h2 className="coffee_info">Locations</h2>
        </div>
        <div>
          <h1 className="coffee_info">2</h1>
          <h2 className="coffee_info">Million Trees</h2>
        </div>
      </div>
      <div>
        <h2 className="coffee-heading">COFFEE: Four Main Roasts</h2>

        <div className="coffee-types-container">
          <div>
            <div className="coffee-type">
              <img
                className="cofee-type-img"
                src={mediumdarkroast}
                alt="coffee"
              ></img>
            </div>
            <h4 className="coffee-type-text">Medium Dark Roast</h4>
          </div>

          <div className="coffee-type-sort-1">
            <h4 className="coffee-type-text">Medium Roast</h4>
            <div className="coffee-type">
              <img
                className="cofee-type-img"
                src={mediumroast}
                alt="coffee"
              ></img>
            </div>
          </div>

          <div>
            <div className="coffee-type">
              <img
                className="cofee-type-img"
                src={lightroast}
                alt="coffee"
              ></img>
            </div>
            <h4 className="coffee-type-text">Light Roast</h4>
          </div>

          <div className="coffee-type-sort-1">
            <h4 className="coffee-type-text">Dark Roast</h4>
            <div className="coffee-type">
              <img
                className="cofee-type-img"
                src={darkroast}
                alt="coffee"
              ></img>
            </div>
          </div>
        </div>
      </div>

      <hr className="style-one" width="60%"></hr>

      <div className="coffeecup">
        <div className="item-1">
          <img src={coffeecup} alt="coffeecup"></img>
        </div>

        <div className="item-2">
          <h1 className="coffee-description">
            Visit our Coffee Shop to get exclusive offers and discounts!
          </h1>
          <button className="coffee-button">
            <h3 className="btn-text">
              Shop <GiCoffeeCup />
            </h3>
          </button>
        </div>
      </div>
      <Footer colorMode="brown" />
    </>
  );
};

export default Coffee;

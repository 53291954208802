// Importing Shared Components
// import Navigation  from './shared-components/Navigation/Navigation'

import Navigation from "./shared-components/Transparent Navbar/Navigation";

// Importing Components
import Home from "./components/Home/Home";
import Explore from "./components/Explore/Explore";
import Contact from "./components/Contact/Contact";
import CardsPage from "./layouts/Cards-Page/cardsPage";
import CardsDetailPage from "./layouts/Cards-Page/cardDetailPage";

// import Login from './components/Login/Login'
import About from "./components/About/About";

// Other Shared Components
import Coffee from "./shared-components/Coffee Page/Coffee";

//Importing Router Components from React Router Dom
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

function App() {
  return (
    <Router>
      <Navigation />
      <Switch>
        <Route exact path="/" component={() => <Home />} />
        <Route path="/explore" exact component={() => <Explore />} />
        <Route path="/about" exact component={() => <About />} />
        <Route path="/blog" exact component={() => <CardsPage />} />
        <Route path="/contact" exact component={() => <Contact />} />
        <Route path="/blog-detail/:id" component={CardsDetailPage} />
        <Route path="/coffee" exact component={() => <Coffee />} />
        <Redirect from="*" to="/" />
      </Switch>
    </Router>
  );
}

export default App;

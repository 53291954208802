import { useState, useEffect } from "react";
import ItemList from "./ItemListsnew";
import "./map.css";

// Images
import {GiBearFace,GiCampingTent} from "react-icons/gi";
import { FaBinoculars,FaPrayingHands } from "react-icons/fa";
import {BiBorderAll} from "react-icons/bi";
import { BiHotel } from "react-icons/bi";
import { RiRestaurant2Line } from "react-icons/ri";

function addMarker(map, lat, lng, uId, destname) {
  const contentString = `<div class="p-2"><h4 class="tooltip-head">${destname}</h4>
                        <h6 class="tooltip-des">Chikmagalur, City in Karnataka</h6></div>
  `;
  const placeindex = toString(uId)
  console.log(typeof(placeindex));

  const marker = new window.google.maps.Marker({
    position: { lat, lng },
    map,
    uId,
    label: {placeindex},
  });

  const infowindow = new window.google.maps.InfoWindow({
    content: contentString,
  });

  return [marker, infowindow];
}

function addListener(map, marker, infowindow, setUId, index, dataofplace) {
  const divid = "uId" + index;

  marker.addListener("mouseover", function () {
    infowindow.open(map, marker);
    setUId(index);
  });

  marker.addListener("mouseout", function () {
    infowindow.close(map, marker);
    setUId(0);
  });

  marker.addListener("click", function () {
    var myvar = setInterval(function () {
      document.getElementById(divid).className += " blink_me";
    }, 100);

    marker.addListener("mouseout", function () {
      clearInterval(myvar);
    });
  });
}

export default function New() {
  const [map, setMap] = useState("");
  const [uId, setUId] = useState(0);
  const [loading, setLoading] = useState("true");
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState(window.$cat);
  useEffect(() => {
    setLoading("true");
    
    setTimeout(() => {
      setMap(
        new window.google.maps.Map(document.getElementById("map"), {
          center: { lat: 13.269, lng: 75.1554 },
          zoom: 10,
        })
      );
    }, 1000);

    let Cards = [];
    fetch(
      "https://chikkamagaluru-1fa59-default-rtdb.firebaseio.com/destination.json"
    )
      .then((res) => res.json())
      .then((data) => {
        for (let eachData in data) {
          if (filter === "all") {
            Cards.push(data[eachData]);
          } else if (data[eachData].category.trim() === filter) {
            Cards.push(data[eachData]);
          }
        }
        setLoading("false");
      });
    setData(Cards);
  }, [filter, setLoading]);

  function clickHandler() {
    data.map((placedata, index) => {
      const [marker1, infowindow1] = addMarker(
        map,
        parseFloat(placedata.lat),
        parseFloat(placedata.long),
        index,
        placedata.destname
      );
      addListener(map, marker1, infowindow1, setUId, index, placedata);
      return 0;
    });
  }

  return (
    <div>
      <div className="explore-container">
        {clickHandler()}
        <div className="map-filter-container">
          <div className="map-header">
            <h1 className="overview_title">Chikkamagaluru</h1>
            <h3 className="title-breadcrumb">Karnataka ,India</h3>
          </div>

          <div className="map-filter py-3">
            <div>
              <button
                title="All"
                className="filter-btn"
                onClick={() => setFilter("all")}
              >
                <BiBorderAll
                className="filter-btn-icon"/>
              </button>

              <button
                title="Adventures"
                className="filter-btn"
                onClick={() => setFilter("Adventure")}
              >
                <GiCampingTent
                className="filter-btn-icon"/>
              </button>

              <button
                title="Wildlife"
                className="filter-btn"
                onClick={() => setFilter("Wildlife")}
              >
                 <GiBearFace 
                className="filter-btn-icon"/>
              </button>

              <button
                title="Sightseeing "
                className="filter-btn"
                onClick={() => setFilter("Sightseeing")}
              >
                <FaBinoculars
                className="filter-btn-icon"/>
              </button>

              <button
                title="Divine Destinations"
                className="filter-btn"
                onClick={() => setFilter("Divine")}
              >
                <FaPrayingHands
                className="filter-btn-icon"/>
              </button>
              <button
                title="Hotels and stays"
                className="filter-btn"
                onClick={() => setFilter("Hotel")}
              >
                <BiHotel
                className="filter-btn-icon"/>
              </button>
              <button
                title="Food"
                className="filter-btn"
                onClick={() => setFilter("Food")}
              >
                <RiRestaurant2Line
                className="filter-btn-icon"/>
              </button>
            </div>
          </div>

          <ItemList uId={uId} data={data} />
        </div>

        <div className="map-container">
          <div
            id="map"
            className="main-map rounded"
            style={{ height: "80vh", width: "100vw", display: "inline-block" }}
          ></div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import "./Footer.css";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import logo from "./logo.png";

import { AiFillFacebook, AiFillInstagram } from "react-icons/ai";
import { index } from "../../variables/content";

function Footer({ colorMode }) {
  //renders items of each heading of the navigation section in footer
  // function navListItemsRender(head) {
  //   return head.map((items) => (
  //     <li>
  //       <a href="https://chikkamagaluru.org/">{items}</a>
  //     </li>
  //   ));
  // }
  //renders heading components of the navigation section in footer
  const navListHeadsLeft = index.footer.navigationLeft.map((nav) => (
    <Col>
      <Link to={nav.link}>
        <h3
          onClick={() => (window.$tmp = nav.head)}
          className="font-weight-bold"
        >
          {nav.head}
        </h3>
      </Link>
      {/* <ul>{navListItemsRender(nav.items)}</ul> */}
    </Col>
  ));
  const navListHeadsRight = index.footer.navigationRight.map((nav) => (
    <Col>
      <Link to={nav.link}>
        <h3
          onClick={() => (window.$tmp = nav.head)}
          className="font-weight-bold"
        >
          {nav.head}
        </h3>
      </Link>
    </Col>
  ));
  return (
    <div className={"footer-" + colorMode}>
      <footer className="py-5 text-dark">
        <div className="container">
          <Row className="text-center mb-4">
            <Col md="2" className="text-center d-block d-sm-none">
              <img alt="" src={logo} className="mb-2 align-top w-25" />
            </Col>
            <Col md="5">
              <Row>{navListHeadsLeft}</Row>
            </Col>
            <Col md="2" className="text-center d-none d-sm-block ">
              <img alt="" src={logo} className="align-top w-50" />
            </Col>
            <Col md="5">
              <Row>{navListHeadsRight}</Row>
            </Col>
          </Row>
          <Row className="text-center">
            <Col className="item social text-dark">
              <a href="https://chikkamagaluru.org/">
                <AiFillFacebook />
              </a>

              <a href="https://www.instagram.com/chikkamagaluru/">
                <AiFillInstagram />
              </a>
            </Col>
          </Row>

          <Row className="text-center">
            <p className="mx-auto mt-2 text-center text-dark copyright">
              <a href="http://chikkamagaluru.org/">
                &copy;Chikmaguluru {new Date().getFullYear()}. We love our
                users!
              </a>
            </p>
          </Row>
        </div>
      </footer>
    </div>
  );
}

export default Footer;

import React from "react";
import { useState } from "react";
import { TiWeatherPartlySunny } from "react-icons/ti";
import { AiFillCloseCircle } from "react-icons/ai";
import "./WeatherWidget.css";
import { Modal } from "react-bootstrap";
export default function WeatherWidget() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        animation={true}
        className="model fade right"
      >
        <AiFillCloseCircle
          className="close-btn text-dark"
          style={{ zIndex: 1203 }}
          onClick={handleClose}
        />
        <Modal.Body className="px-3 pb-3 rounded ">
          <div>
            <a
              style={{ zIndex: 123 }}
              class="weatherwidget-io"
              href="https://forecast7.com/en/13d3275d78/chickmagaluru/"
              data-label_1="CHIKKAMAGALURU"
              data-label_2="WEATHER"
              data-icons="Climacons Animated"
              data-mode="Both"
              data-theme="pure"
              data-days="7"
              data-basecolor=""
              data-accent=""
            >
              CHIKKAMAGALURU WEATHER
            </a>
            {
              !(function (d, s, id) {
                var js,
                  fjs = d.getElementsByTagName(s)[0];
                js = d.createElement(s);
                js.id = id;
                js.src = "https://weatherwidget.io/js/widget.min.js";
                fjs.parentNode.insertBefore(js, fjs);
              })(document, "script", "weatherwidget-io-js")
            }
          </div>
        </Modal.Body>
      </Modal>
      <TiWeatherPartlySunny
        onClick={() => handleShow()}
        className="text-white float-right"
        style={{ fontSize: "2.5rem" }}
      />
    </>
  );
}

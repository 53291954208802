import React from "react";
import "./RedirectButton.css";

export const RedirectButton = ({
  children,
  type,
  onClick,
  buttonStyle,
  buttonSize,
  buttonColor,
}) => {
  return (
    <div id="cofbtn" className="container">
      <button className="learn-more redirect-btn">
        <span className="circle" style={{ background: buttonColor }} aria-hidden="true">
          <span className="icon arrow"></span>
        </span>
        <span style={{ color: buttonColor }} className="button-text">&nbsp; {children}</span>
      </button>
    </div>
  );
};

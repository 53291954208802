import React from "react";
import "./Hero.css";
import sample from "../../Images-Videos-GIF/videoplayback.mp4";

const Hero = () => {
  return (
    <div>
      <header>
        <div class="overlay">
        </div>
        <video
          playsinline="playsinline"
          autoplay="autoplay"
          muted="muted"
          loop="loop"
        >
          <source src={sample} type="video/mp4" />
        </video>
        <div class="container  h-100">
          <div class="d-flex h-100 text-center align-items-center"></div>
        </div>
      </header>
    </div>
  );
};

export default Hero;

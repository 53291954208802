import React from "react";
import { Link } from "react-router-dom";
import "./Faq.css";

class Faq extends React.Component {
  render() {
    return (
      <>
        <h2 className="head">FAQ</h2>

        <div className="main-container">
          <div className="container">
            <div className="faq-drawer">
              <input
                className="faq-drawer__trigger"
                id="faq-drawer"
                type="checkbox"
              />
              <label className="faq-drawer__title" for="faq-drawer">
                What is the best time to visit Chikmagalur for a vacation?{" "}
              </label>
              <div className="faq-drawer__content-wrapper">
                <div className="faq-drawer__content">
                  <p>
                    It is highly recommended to visit Chikkamagalur during
                    winter (September-February). The temperatures in summer
                    (March- June) ranges from 25-35 degrees Celsius, making it
                    favourable for sightseeing. However, its not the best time
                    to visit Chikkamagalur at its best. With waterfalls at their
                    glorious best and abundant greenery everywhere, nature is at
                    its best in Chikkamaglur during its monsoon. However, due to
                    heavy rainfall, it might be difficult to visit places. The
                    temperature is winter ranges from 10 to 28 degrees Celsius,
                    making it the best time to visit Chikkamagalur.
                  </p>
                  <a
                    className="con-btn text-center"
                    type="button"
                    href="/blog-detail/245a4f50-8105-11eb-8274-27379ac005c7"
                  >
                    View more
                  </a>
                </div>
              </div>
            </div>

            <div className="faq-drawer">
              <input
                className="faq-drawer__trigger"
                id="faq-drawer-2"
                type="checkbox"
              />
              <label className="faq-drawer__title" for="faq-drawer-2">
                How can I reach Chikmagalur?
              </label>
              <div className="faq-drawer__content-wrapper">
                <div className="faq-drawer__content">
                  <p>
                    Chikkamagalur is well connected to other major cities of the
                    country by air, road and rail. Manglore Airport is the
                    nearest Airport to Chikkamagalur and is well connected to
                    flights from Chennai, Mumbai, Kolkata and other major Indian
                    cities. One can hire a pre-paid taxi from the airport to
                    Chikkamagalur which is around 113kms away from the Mangalore
                    airport. Chikkamagalur is well connected to cities like
                    Bangalore, Mangalore, Hubli and Hassan by state-run buses
                    and private luxury buses. Most tourists from nearby cities
                    often prefer driving their own vehicles. The nearest railway
                    station is located at Kadur, at a distance of about 40kms
                    away. From Kadur, one can take a taxi to reach
                    Chikkamagalur.
                  </p>
                  <a
                    className="con-btn text-center"
                    type="button"
                    href="/blog-detail/245a4f50-8105-11eb-8274-27379ac005c7"
                  >
                    View more
                  </a>
                </div>
              </div>
            </div>

            <div className="faq-drawer">
              <input
                className="faq-drawer__trigger"
                id="faq-drawer-3"
                type="checkbox"
              />
              <label className="faq-drawer__title" for="faq-drawer-3">
                Which is the highest point in Karnataka?
              </label>
              <div className="faq-drawer__content-wrapper">
                <div className="faq-drawer__content">
                  <p>
                    Mullayanagiri is the highest peak in Karnataka. 1,930 metres
                    (6,330 ft)
                  </p>
                  <div 
                    onClick={() => (window.$cat = "Adventure")} >
                  <Link
                    className="con-btn text-center"
                    type="button"      
                    to="/explore"
                  >
                    View more
                  </Link></div>
                </div>
              </div>
            </div>
            <div className="faq-drawer">
              <input
                className="faq-drawer__trigger"
                id="faq-drawer-4"
                type="checkbox"
              />
              <label className="faq-drawer__title" for="faq-drawer-4">
                Is camping allowed in Kudremukh?{" "}
              </label>
              <div className="faq-drawer__content-wrapper">
                <div className="faq-drawer__content">
                  <p>
                    Camping on the Kudremukh trek is not allowed. However, there
                    are many homestays that offer tent stay at the starting
                    point. 
                  </p>
                  <a
                    className="con-btn text-center"
                    type="button"                    
                    href="/blog-detail/245a4f50-8105-11eb-8274-27379ac005c7"

                  >
                    View more
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="faq-drawer">
              <input
                className="faq-drawer__trigger"
                id="faq-drawer-5"
                type="checkbox"
              />
              <label className="faq-drawer__title" for="faq-drawer-5">
                How many days are enough for Chikmagalur?
              </label>
              <div className="faq-drawer__content-wrapper">
                <div className="faq-drawer__content">
                  <p>
                    You will require around 4-5 days to explore the well-known
                    places. Most of the attractions are located at a distance of
                    10-25 kms from main city of Chikkamagalur. Hence require
                    some travelling time.
                  </p>
                  <a
                    className="con-btn text-center"
                    type="button"
                    href="/blog-detail/245a4f50-8105-11eb-8274-27379ac005c7"
                  >
                    View more
                  </a>
                </div>
              </div>
            </div>

            <div className="faq-drawer">
              <input
                className="faq-drawer__trigger"
                id="faq-drawer-6"
                type="checkbox"
              />
              <label className="faq-drawer__title" for="faq-drawer-6">
                What are the must visit attractions in Chikmagalur?
              </label>
              <div className="faq-drawer__content-wrapper">
                <div className="faq-drawer__content">
                  <p>
                    In Circuit 1: Mullayanagiri, Seetalayanagiri, Kavikalgandi
                    Viewpoint, Jhari Falls, Datta peeta/Bababudangiri,
                    Manikyadara Waterfalls, Galikere In Circuit 2: Deviramma
                    Temple, Kallattigiri Falls, Kemmannugundi, Z point, Hebbe
                    Falls, Amritapura Temple In Circuit 3: Bandekallu
                    Gudda(Trek), Hirekollale Lake, Muthodi Forest Safari,
                    Mallandur Sunset Point In Circuit 4: Halebeedu, Beluru,
                    Belawadi, Yagachi Backwaters In Circuit 5: Ettina
                    Buja(Trek), Devaramane View Point, College Gudda View
                    point , Ballalarayana Durga Viewpoint
                  </p>
                  <a
                    className="con-btn text-center"
                    type="button"       
                    onClick={() => (window.$cat = "All")}          
                    href="/explore"

                  >
                    View more
                  </a>
                </div>
              </div>
            </div>

            <div className="faq-drawer">
              <input
                className="faq-drawer__trigger"
                id="faq-drawer-7"
                type="checkbox"
              />
              <label className="faq-drawer__title" for="faq-drawer-7">
                What can I buy in Chikmagalur?
              </label>
              <div className="faq-drawer__content-wrapper">
                <div className="faq-drawer__content">
                  <p>
                    Traditional South Indian Coffee, varieties of chocolates
                    mixed with coffee, spices and souvenirs like local
                    artefacts, sandalwood idols and perfumes are some of the
                    most sought after things by tourists in Chikkamagaluru.
                  </p>
                  <a
                    className="con-btn text-center"
                    type="button"                    
                    href="/blog-detail/245a4f50-8105-11eb-8274-27379ac005c7"

                  >
                    View more
                  </a>
                </div>
              </div>
            </div>
            <div className="faq-drawer">
              <input
                className="faq-drawer__trigger"
                id="faq-drawer-8"
                type="checkbox"
              />
              <label className="faq-drawer__title" for="faq-drawer-8">
                What are the strict rules and dress code while visiting temples
                in Chikmagalur?
              </label>
              <div className="faq-drawer__content-wrapper">
                <div className="faq-drawer__content">
                  <p>
                    Only Sringeri Sharada Peeth Temple has photography
                    prohibited inside. But it is mandatory for everyone to
                    remove foot wears, dress decently and maintain a peaceful
                    atmosphere inside the temple area. It is also advised to
                    keep your mobile phones on silent mode.
                  </p>
                  <a
                    className="con-btn text-center"
                    type="button"                    
                    href="/blog-detail/245a4f50-8105-11eb-8274-27379ac005c7"

                  >
                    View more
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Faq;

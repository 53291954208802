import React from "react";
import "./BlogCard.css";

export const BlogCard = ({ card }) => {
  return (
    <div className="post-module hover">
      <div className="thumbnail">
        <img className="image-main" src={card.tile_image} alt="" />
      </div>
      <div className="post-content">
        <h1 className="title">{card.title}</h1>
        <p className="description">{card.description.slice(0, 100) + "..."}</p>
        <div className="post-meta">
        </div>
      </div>
    </div>
  );
};

import React from "react";
import ReactLoading from "react-loading";
import "./cardsPage.css";
import { RedirectButton } from "../../shared-components/RedirectButton/RedirectButton";
import { BlogCard } from "../../shared-components/BlogCard/BlogCard";
import { Row, Col } from "reactstrap";
import Footer from "../../shared-components/Footer/Footer";
import Hero from "../../shared-components/Hero/Hero";
import Markdown from "markdown-to-jsx";
// import { MdDateRange } from "react-icons/md";
import { Redirect, Link } from "react-router-dom";

class CardsDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      card: {
        index: "",
        image: "",
        links: "",
        title: "",
        author: "",
        body: "",
      },
      isEmpty: true,
      isLoading: true,
      suggestedCards: [],
    };
  }
  async componentDidMount() {
    let Cards = [];
    await fetch(
      "https://chikkamagaluru-1fa59-default-rtdb.firebaseio.com/blog.json"
    )
      .then((res) => res.json())

      .then((data) => {
        for (let eachData in data) Cards.push(data[eachData]);
      });
    if (Cards === null) {
      this.setState({ isEmpty: true, isLoading: false });
    } else if (Cards.length > 0) {
      const id = this.props.match.params.id;
      this.setState({ card: Cards.find((post) => post.index === id) });
      let suggest = [];
      Cards.forEach((post) => {
        if (
          post.index !== id &&
          post.tags.trim().startsWith(this.state.card.tags.split(" ")[0])
        ) {
          suggest.push(post);
        }
      });
      let pointer = suggest.length;
      for (let i = 4; i > pointer; i--) {
        suggest.push(
          Cards.find((post) => post.index !== id && !suggest.includes(post))
        );
      }
      this.setState({
        suggestedCards: suggest.slice(0, 4),
        isEmpty: false,
        isLoading: false,
      });
    }

    window.scrollTo(0, 0);
  }
  render() {
    if (typeof window.$tmp === "undefined") {
      return <Redirect to="/blog" />;
    }
    let cardItems = this.state.suggestedCards.map((card) => {
      return (
        <Col className="mb-4" md="6" sm="6" xs="12" lg="3">
          <Link
            onClick={() => this.componentDidMount()}
            to={`/blog-detail/${card.index}`}
          >
            <BlogCard card={card} />
          </Link>
        </Col>
      );
    });
    return (
      <>
        <div
          className={this.state.card.title.length === 0 ? "d-auto" : "d-none"}
        >
          <ReactLoading
            className="mx-auto m-5 h-100"
            type="spin"
            color="#4A6D39"
            height={100}
            width={50}
          />
        </div>
        <div className={this.state.card.title.length > 0 ? "d-auto" : "d-none"}>
          <Hero
            img={this.state.card.image}
            pimg={this.state.card.tile_image}
            alt={this.state.card.title}
          />
          <div className="home__hero-section">
            <div className="container container-blog-detail">
              <Row>
                <Col md="12">
                  <Markdown className="body">{this.state.card.body}</Markdown>
                  <br />
                  <a
                    href={this.state.card.links}
                    className="font-raleway text-center"
                  >
                    <RedirectButton buttonColor="#689D4F" children="See More" />
                  </a>
                  <hr />
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <h3 className="text-left">Written By</h3>
                  <h1>{this.state.card.author}</h1>
                  <p>
                    The content and images used on this site are copyright
                    protected and copyrights{" "}
                  </p>
                </Col>
              </Row>
            </div>
            <hr />
            <div className="container">
              <Row>
                <Col md="6">
                  <h2 className="mb-3 font-weight-bold">Suggested blogs for you</h2>
                </Col>
              </Row>
              <Row>{cardItems}</Row>
              <Row className="mx-auto text-center">
                <a href="/blog" className="mx-auto">
                  <RedirectButton buttonColor="#689D4F" children="See More" />
                </a>
              </Row>
            </div>
          </div>
        </div>
        <Footer colorMode="green" />
      </>
    );
  }
}

export default CardsDetailPage;

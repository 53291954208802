import React from "react";
import { Container } from "react-bootstrap";

import App from "../../shared-components/GoogleMap/Map";
import Footer from "../../shared-components/Footer/Footer";

import "./explore.css";

function Explore() {
  window.scrollTo(0, 0);
  return (
    <>
      <Container fluid className="map-box">
        <div className="p-5 mb-3"></div>
        <App />
      </Container>
      <Footer colorMode="green" />
    </>
  );
}

export default Explore;

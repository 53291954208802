import React, { Component } from "react";
import "./insta.css";
import "./card.css";

export default class instagram extends Component {
  // sanket = "IGQVJVSEhiZAlVBeVN2S3NkNmRPU1E2V3ZANRHhNWWVkMG9KUmJpaWNYYnNPSWJMOThmNkxVRU1vd2dsUVRqT0RBMU05NklHTk5DN1UtcDl3bE9qbHlBYTBUSTlsZAVZAEOEp3UzFqQXlSRy04ZAkZAXcElqSAZDZD"
  // dscwow = "IGQVJWUENMV212aW9zV1I2WFltNUs5VWo5SWtSYVNYTU5oTlNYSC10R0lhZAlpaSmlEbkRaM1FjQ0ppX0tadUxSbWZAKbzE3bkg4WVQ4anNfNGU3VTVGSVJwSmI2QmFVZAE9uNWVHTHpIZA2lnbDZAiLW94egZDZD"
  // chikkamagaluru = "IGQVJYaDNHMnJTdXJ0aU9wZAENrNTBPeVlMMVVYeE1KWEw4Q1diQWZAqZAi1pbENqNE9MdkNEUUZA0dl9DX2FhLWlRRkE1bEJBaGtld2dWS29LMzN5WFBVOUw3bjVpLXlwblJFenAtVEt1RU1GdmVYVGQ1RgZDZD"

  state = {
    profile: [],
    posts: [],
    token:
      "IGQVJYaDNHMnJTdXJ0aU9wZAENrNTBPeVlMMVVYeE1KWEw4Q1diQWZAqZAi1pbENqNE9MdkNEUUZA0dl9DX2FhLWlRRkE1bEJBaGtld2dWS29LMzN5WFBVOUw3bjVpLXlwblJFenAtVEt1RU1GdmVYVGQ1RgZDZD",
  };

  async componentDidMount() {
    const token = this.state.token;
    const query =
      "id,username,timestamp,caption,media_url,media_type,permalink,children";
    const final_url =
      "https://graph.instagram.com/me/media?fields=" +
      query +
      "&access_token=" +
      token;

    const response = await fetch(final_url);
    const data = await response.json();
    console.log("1");
    console.log(data.data);
    await this.setState({ posts: data.data });
    this.setState({ profile: data.data[0] });
  }
  render() {
    return (
      <div className="instagram-container">
        <div className="instagram_container_1">
          {this.state.posts.slice(0, 3).map((post, index) => (
            <div className="instahover">
              <a href={post.permalink}>
                <img
                  key={post.id}
                  src={post.media_url}
                  className={"grid__img1 insta_img_" + index}
                  loading="lazy"
                  alt="instagram"
                />
                <i className="instaicon fa fa-instagram"></i>
              </a>
            </div>
          ))}
        </div>

        <div className="instagram_container_2">
          {this.state.posts.slice(3, 7).map((post, index) => (
            <div className="instahover">
              <a href={post.permalink}>
                <img
                  key={post.id}
                  src={post.media_url}
                  className={"grid__img2 insta_img_" + index}
                  loading="lazy"
                  alt="instagram"
                />
                <i className="instaicon fa fa-instagram"></i>
              </a>
            </div>
          ))}
        </div>

        <div className="instagram_container_3">
          {this.state.posts.slice(7, 10).map((post, index) => (
            <div className="instahover">
              <a href={post.permalink}>
                {" "}
                <img
                  key={post.id}
                  src={post.media_url}
                  className={"grid__img3 insta_img_" + index}
                  loading="lazy"
                  alt="instagram"
                />
                <i className="instaicon fa fa-instagram"></i>
              </a>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

import React from "react";
import "./TitleText.css";

export const TitleText = ({ text , color}) => {
  return (
    <div>
      <p className="popout d-none d-sm-block" style={{color:color}}>{text}</p>
      <p className="popout popout-sm d-block d-sm-none" style={{color:color}}>{text}</p>
    </div>
  );
};
